<template>
    <div>
      <v-container>
        <v-row no-gutters>
          <v-col cols="12" md="12">
            <v-card>
              <div>
              <v-btn small class="ma-2" @click="period_vecprovinceEdit()" fab color="warning"><v-icon>mdi-pencil</v-icon></v-btn>
            </div>
            <div class="pa-2 font-weight-bold">
                {{ period_vecprovinces.period_vecprovince_comment }}
            </div>
              <v-simple-table>
                <tbody>
                  <tr>
                    <td>ครั้งที่</td>
                    <td class="text-center font-weight-bold">
                      {{ period_vecprovinces.period_vecprovince_times }}
                    </td>
                    <td>ปี</td>
                    <td class="text-center font-weight-bold">
                      {{  period_vecprovinces.period_vecprovince_year }}
                    </td>
                  </tr>
                  <tr>
                    <td>เริ่มวันที่</td>
                    <td class="text-center font-weight-bold">
                      {{ period_vecprovinces.period_vecprovince_start   | moment("add", "543 years")
                        | moment("D MMMM YYYY") }}
                    </td>
  
                    <td>สิ้นสุดวันที่</td>
                    <td class="text-center font-weight-bold">
                      {{ period_vecprovinces.period_vecprovince_stop   | moment("add", "543 years")
                        | moment("D MMMM YYYY") }}
                    </td>
                  </tr>
               
                  <tr>
                    <td>สถานะระบบ</td>
                    <td class="text-center font-weight-bold">
                  <v-chip color="green" dark v-if="period_vecprovinces.period_vecprovince_enable==='1'">เปิด</v-chip>
                  <v-chip color="red" dark v-else-if="period_vecprovinces.period_vecprovince_enable==='0'">ปิด</v-chip>
                    </td>
  
                    <td>สถานะ แนบไฟล์</td>
                    <td class="text-center font-weight-bold">
                      <v-chip color="green" dark v-if="period_vecprovinces.period_vecprovince_enfile==='1'">เปิด</v-chip>
                  <v-chip color="red" dark v-else-if="period_vecprovinces.period_vecprovince_enfile==='0'">ปิด</v-chip>
                
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card></v-col
          >       
        </v-row>
    
  
        <!-- V-model editperiod_vecprovincedialog -->
        <v-layout row justify-center>
          <v-dialog v-model="editperiod_vecprovincedialog" persistent max-width="80%">
            <v-card class="mx-auto pa-6">
              <base-material-card
                color="yellow"
                icon="mdi-clipboard-text"
                title="กำหนดการ สอจ. ประเมิน ภาค ข"
                class="px-5 py-3 text_google"
              ></base-material-card>
              <v-card-text>
                <v-form ref="editperiod_vecprovinceform" lazy-validation>
                  <v-container grid-list-md>
                    <v-layout wrap>
                      <v-flex xs12 md6>
                        <v-select
                          v-model="editperiod_vecprovince.period_vecprovince_year"
                          :items="period_vecprovinceselect"
                          item-text="text"
                          item-value="value"
                          label="เลือกปีการศึกษา"
                        >
                        </v-select>
                        <v-spacer></v-spacer>
                      </v-flex>
                      <v-flex xs12 md6>
                        <v-text-field
                          type="number"
                          label="ครั้งที่"
                          v-model="editperiod_vecprovince.period_vecprovince_times"
                          required
                          :rules="[v => !!v || '']"
                        ></v-text-field>
                      </v-flex>
                   
                      <v-flex xs12 md6>
                        <v-text-field
                          type="date"
                          label="วันที่เริ่ม"
                          v-model="editperiod_vecprovince.period_vecprovince_start"
                          required
                          :rules="[v => !!v || '']"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 md6>
                        <v-text-field
                          type="date"
                          label="วันที่สิ้นสุด"
                          v-model="editperiod_vecprovince.period_vecprovince_stop"
                          required
                          :rules="[v => !!v || '']"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 md6>
                        <v-switch
                          v-model="editperiod_vecprovince.period_vecprovince_enable_sw"
                          label="เปิดใช้งานสำนักงานอาชีวศึกษาจังหวัด"
                        ></v-switch>
                      </v-flex>
  
                      <v-flex xs12 md6>
                        <v-switch
                          v-model="
                            editperiod_vecprovince.period_vecprovince_enfile_sw
                          "
                          label="เปิดใช้งานการแนบไฟล์"
                        ></v-switch>
                      </v-flex>
                    </v-layout>
                  </v-container>
                  <small>* จำเป็น</small>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  large
                  @click.stop="editperiod_vecprovincedialog = false"
                  rounded
                >
                  <v-icon dark>mdi-close</v-icon>ยกเลิก
                </v-btn>
                <v-btn
                  large
                  color="warning"
                  @click.stop="editperiod_vecprovinceSubmit()"
                  rounded
                >
                  <v-icon dark>mdi-pencil</v-icon>&nbsp;บันทึก
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>
      </v-container>
  
      <v-container fluid>
        <v-snackbar
          v-model="snackbar.show"
          multi-line
          vertical
          top
          auto-height
          :timeout="snackbar.timeout"
          :color="snackbar.color"
        >
          <v-icon large>{{ snackbar.icon }}</v-icon>
          <br />
          {{ snackbar.text }}
          <v-btn dark @click="snackbar.show = false">
            Close
          </v-btn>
        </v-snackbar>
      </v-container>
    </div>
  </template>
  <script>
  import VueMoment from "vue-moment";
  import moment from "moment-timezone";
  export default {
    name: "period_vecprovince",
    data() {
      return {
        VueMoment,
        moment,
        loading: true,
        ApiKey: "HRvec2021",
        valid: true,
        addperiod_vecprovincedialog: false,
        editperiod_vecprovincedialog: false,
        deleteperiod_vecprovincedialog: false,
        snackbar: {
          show: false,
          color: "",
          timeout: 5000,
          icon: "",
          text: ""
        },     
        period_vecprovinces: [],
        addperiod_vecprovince: {},
        editperiod_vecprovince: {},
        search: "",
        pagination: {},
   
    
        period_vecprovinceselect: [
          {
            value: "2564",
            text: "2564"
          },
          {
            value: "2565",
            text: "2565"
          },
          {
            value: "2566",
            text: "2566"
          },
          {
            value: "2567",
            text: "2567"
          },
          {
            value: "2568",
            text: "2568"
          },
          {
            value: "2569",
            text: "2569"
          },
          {
            value: "2570",
            text: "2570"
          }
        ],
        data_syslog: {}
      };
    },
    async mounted() {
      this.period_vecprovinceQueryAll();
    },
    methods: {
      async period_vecprovinceQueryAll() {
        this.loading = true;
        let result = await this.$http
          .post("period_vecprovince.php", {
            ApiKey: this.ApiKey,
            period_vecprovinc_id:"20102"
          })
          .finally(() => (this.loading = false));
        this.period_vecprovinces = result.data;
      },     
  
      async period_vecprovinceEdit() {
        let result = await this.$http.post("period_vecprovince.php", {
          ApiKey: this.ApiKey,
          period_vecprovinc_id:"20102"
        });
        this.editperiod_vecprovince = result.data;
        if (this.editperiod_vecprovince.period_vecprovince_enable == 1){
          this.editperiod_vecprovince.period_vecprovince_enable_sw = true;
        }  else{
         this.editperiod_vecprovince.period_vecprovince_enable_sw = false;      
        } 
  
          if (this.editperiod_vecprovince.period_vecprovince_enfile == 1){
          this.editperiod_vecprovince.period_vecprovince_enfile_sw = true;
        }  else{
         this.editperiod_vecprovince.period_vecprovince_enfile_sw = false;      
        } 
        this.editperiod_vecprovincedialog = true; 
        
      },
  
  
      async editperiod_vecprovinceSubmit() {
        if (this.editperiod_vecprovince.period_vecprovince_enable_sw == true){
        this.editperiod_vecprovince.period_vecprovince_enable = "1";  
        }             
        else{
         this.editperiod_vecprovince.period_vecprovince_enable = "0"; 
        } 
  
         if (this.editperiod_vecprovince.period_vecprovince_enfile_sw == true){
        this.editperiod_vecprovince.period_vecprovince_enfile = "1";  
        }             
        else{
         this.editperiod_vecprovince.period_vecprovince_enfile = "0"; 
        } 
  
  
        this.editperiod_vecprovince.ApiKey = this.ApiKey;
        let result = await this.$http.post(
          "period_vecprovince.update.php",
          this.editperiod_vecprovince
        );
        
        if (result.data.status == true) {
          this.period_vecprovince = result.data;
  
           Swal.fire({
              icon: 'success',
              title: 'ดำเนินการบันทึกข้อมูลเรียบร้อย',
              showConfirmButton: false,
              timer: 1500,
            })
  
  
          this.period_vecprovinceQueryAll();
          let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
          this.data_syslog.ApiKey = this.ApiKey;
          this.data_syslog.user_account = userSession.user_name;
          this.data_syslog.event_log = "update";
          this.data_syslog.page_log = "period_vecprovince";
          this.data_syslog.table_log = "period_vecprovince";
          this.data_syslog.date_times = this.date_today_log;
          await this.$http.post("data_syslog.insert.php", this.data_syslog);
        } else {
          Swal.fire({
              icon: 'error',
              title: 'ดำเนินการผิดพลาด',
              showConfirmButton: false,
              timer: 1500,
            })
        }
        this.editperiod_vecprovincedialog = false;
      },
  
   
  
   
    },
    computed: {
      pages() {
        if (
          this.pagination.rowsPerPage == null ||
          this.pagination.totalItems == null
        )
          return 0;
  
        return Math.ceil(
          this.pagination.totalItems / this.pagination.rowsPerPage
        );
      },
      date_today_log() {
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, "0");
        let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = today.getFullYear() + 543;
        let time =
          today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        today = dd + "/" + mm + "/" + yyyy + "/" + time;
        return today;
      }
    }
  };
  </script>
  