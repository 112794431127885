<template>
    <div>
      <v-container>
        <v-row no-gutters>
          <v-col cols="12" md="12">
            <v-card>
              <div class="right-text">
                <v-btn
                  small
                  class="ma-2"
                  @click="period_sedirectorEdit()"
                  fab
                  color="warning"
                  ><v-icon>mdi-pencil</v-icon></v-btn
                >
              </div>
              <div class="left-text font-weight-bold pa-2">
                {{ period_sedirectors.period_sedirector_comment }}
              </div>
              <v-simple-table>
                <tbody>
                  <tr>
                    <td>ครั้งที่</td>
                    <td class="text-center font-weight-bold">
                      {{ period_sedirectors.period_sedirector_times }}
                    </td>
                    <td>ปี</td>
                    <td class="text-center font-weight-bold">
                      {{ period_sedirectors.period_sedirector_year }}
                    </td>
                  </tr>
                  <tr>
                    <td>เริ่มวันที่</td>
                    <td class="text-center font-weight-bold">
                      {{
                        period_sedirectors.period_sedirector_start
                          | moment("add", "543 years")
                          | moment("D MMMM YYYY")
                      }}
                    </td>
  
                    <td>สิ้นสุดวันที่</td>
                    <td class="text-center font-weight-bold">
                      {{
                        period_sedirectors.period_sedirector_stop
                          | moment("add", "543 years")
                          | moment("D MMMM YYYY")
                      }}
                    </td>
                  </tr>
  
                  <tr>
                    <td>สถานะระบบ</td>
                    <td class="text-center font-weight-bold">
                      <v-chip
                        color="green"
                        dark
                        v-if="period_sedirectors.period_sedirector_enable === '1'"
                        >เปิด</v-chip
                      >
                      <v-chip
                        color="red"
                        dark
                        v-else-if="
                          period_sedirectors.period_sedirector_enable === '0'
                        "
                        >ปิด</v-chip
                      >
                    </td>
  
                    <td>สถานะประมวลผล</td>
                    <td class="text-center font-weight-bold">
                      <v-chip
                        color="green"
                        dark
                        v-if="
                          period_sedirectors.period_sedirector_process === '1'
                        "
                        >เปิด</v-chip
                      >
                      <v-chip
                        color="red"
                        dark
                        v-else-if="
                          period_sedirectors.period_sedirector_process === '0'
                        "
                        >ปิด</v-chip
                      >
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card></v-col
          >
        </v-row>
  
        <!-- V-model editperiod_sedirectordialog -->
        <v-layout row justify-center>
          <v-dialog
            v-model="editperiod_sedirectordialog"
            persistent
            max-width="60%"
          >
            <v-card class="mx-auto pa-6">
              <base-material-card
                color="yellow"
                icon="mdi-clipboard-text"
                title="แก้ไขกำหนดการ"
                class="px-5 py-3 text_google"
              ></base-material-card>
              <v-card-text>
                <v-form ref="editperiod_sedirectorform" lazy-validation>
                  <v-container grid-list-md>
                    <v-layout wrap>
                      <v-flex xs12 md6>
                        <v-select
                          v-model="editperiod_sedirector.period_sedirector_year"
                          :items="period_sedirectorselect"
                          item-text="text"
                          item-value="value"
                          label="เลือกปีการศึกษา"
                          single-line
                          rounded
                          outlined
                        >
                        </v-select>
                        <v-spacer></v-spacer>
                      </v-flex>
                      <v-flex xs12 md6>
                        <v-text-field
                          type="number"
                          label="ครั้งที่"
                          v-model="editperiod_sedirector.period_sedirector_times"
                          required
                          :rules="[v => !!v || '']"
                          rounded
                          outlined
                        ></v-text-field>
                      </v-flex>
  
                      <v-flex xs12 md6>
                        <v-text-field
                          type="date"
                          label="วันที่เริ่ม"
                          v-model="editperiod_sedirector.period_sedirector_start"
                          required
                          :rules="[v => !!v || '']"
                          rounded
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 md6>
                        <v-text-field
                          type="date"
                          label="วันที่สิ้นสุด"
                          v-model="editperiod_sedirector.period_sedirector_stop"
                          required
                          :rules="[v => !!v || '']"
                          rounded
                          outlined
                        ></v-text-field>
                      </v-flex>
                     
                      <v-flex xs12 md6>
                        <v-switch
                          v-model="
                            editperiod_sedirector.period_sedirector_enable_sw
                          "
                          label="เปิดใช้ระบบ"
                        ></v-switch>
                      </v-flex>
                      <v-flex xs12 md6>
                        <v-switch
                          v-model="
                            editperiod_sedirector.period_sedirector_enable_sw_process
                          "
                          label="เปิดใช้ระบบประมวลผล"
                        ></v-switch>
                      </v-flex>
                    </v-layout>
                  </v-container>
                  <small>* จำเป็น</small>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  large
                  @click.stop="editperiod_sedirectordialog = false"
                  rounded
                >
                  <v-icon dark>mdi-close</v-icon>ยกเลิก
                </v-btn>
                <v-btn
                  large
                  color="warning"
                  @click.stop="editperiod_sedirectorSubmit()"
                  rounded
                >
                  <v-icon dark>mdi-pencil</v-icon>&nbsp;บันทึก
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>
      </v-container>
  
      <v-container fluid>
        <v-snackbar
          v-model="snackbar.show"
          multi-line
          vertical
          top
          auto-height
          :timeout="snackbar.timeout"
          :color="snackbar.color"
        >
          <v-icon large>{{ snackbar.icon }}</v-icon>
          <br />
          {{ snackbar.text }}
          <v-btn dark @click="snackbar.show = false">
            Close
          </v-btn>
        </v-snackbar>
      </v-container>
    </div>
  </template>
  <script>
  import VueMoment from "vue-moment";
  import moment from "moment-timezone";
  export default {
    data() {
      return {
        VueMoment,
        moment,
        loading: true,
        ApiKey: "HRvec2021",
        valid: true,
        addperiod_sedirectordialog: false,
        editperiod_sedirectordialog: false,
        deleteperiod_sedirectordialog: false,
        snackbar: {
          show: false,
          color: "",
          timeout: 5000,
          icon: "",
          text: ""
        },
        period_sedirectors: [],
        addperiod_sedirector: {},
        editperiod_sedirector: {},
        search: "",
        pagination: {},
        headers: [
          { text: "ครั้งที่", align: "center", value: "period_sedirector_times" },
          { text: "ปี", align: "center", value: "period_sedirector_year" },
          {
            text: "เริ่มวันที่",
            align: "center",
            value: "period_sedirector_start"
          },
          {
            text: "สิ้นสุดวันที่",
            align: "center",
            value: "period_sedirector_stop"
          },
          {
            text: "วันที่นับถึง",
            align: "center",
            value: "period_sedirector_dmycal"
          },
  
          { text: "สถานะ", align: "center", value: "period_sedirector_enable" },
          {
            text: "ประมวลผล",
            align: "center",
            value: "period_sedirector_process"
          },
          {
            text: "แก้ไข",
            align: "center",
            value: "actions",
            icon: "mdi-file-document-edit"
          },
          {
            text: "ลบ",
            align: "center",
            value: "action_s",
            icon: "mdi-delete-forever"
          }
        ],
        rowsperpage: [
          25,
          50,
          100,
          {
            text: "All",
            value: -1
          }
        ],
        period_sedirectorselect: [
          {
            value: "2564",
            text: "2564"
          },
          {
            value: "2565",
            text: "2565"
          },
          {
            value: "2566",
            text: "2566"
          },
          {
            value: "2567",
            text: "2567"
          },
          {
            value: "2568",
            text: "2568"
          },
          {
            value: "2569",
            text: "2569"
          },
          {
            value: "2570",
            text: "2570"
          }
        ],
        data_syslog: {},
        linemassage: {}
      };
    },
    async mounted() {
      this.period_sedirectorQueryAll();
    },
    methods: {
      async period_sedirectorQueryAll() {
        this.loading = true;
        let result = await this.$http
          .post("period_sedirector.php", {
            ApiKey: this.ApiKey,
            period_sedirector_id: "204"
          })
          .finally(() => (this.loading = false));
        this.period_sedirectors = result.data;
      },
  
      async period_sedirectorEdit() {
        let result = await this.$http.post("period_sedirector.php", {
          ApiKey: this.ApiKey,
          period_sedirector_id: "204"
        });
        this.editperiod_sedirector = result.data;
  
        if (this.editperiod_sedirector.period_sedirector_enable == 1)
          this.editperiod_sedirector.period_sedirector_enable_sw = true;
        else this.editperiod_sedirector.period_sedirector_enable_sw = false;
  
        if (this.editperiod_sedirector.period_sedirector_process == 1)
          this.editperiod_sedirector.period_sedirector_enable_sw_process = true;
        else
          this.editperiod_sedirector.period_sedirector_enable_sw_process = false;
  
        this.editperiod_sedirectordialog = true;
      },
      async editperiod_sedirectorSubmit() {
        if (this.editperiod_sedirector.period_sedirector_enable_sw == true)
          this.editperiod_sedirector.period_sedirector_enable = "1";
        else this.editperiod_sedirector.period_sedirector_enable = "0";
  
        if (
          this.editperiod_sedirector.period_sedirector_enable_sw_process == true
        )
          this.editperiod_sedirector.period_sedirector_process = "1";
        else this.editperiod_sedirector.period_sedirector_process = "0";
  
        this.editperiod_sedirector.ApiKey = this.ApiKey;
        let result = await this.$http.post(
          "period_sedirector.update.php",
          this.editperiod_sedirector
        );
        if (result.data.status == true) {
          this.period_sedirector = result.data;
          Swal.fire({
            icon: "success",
            title: "แก้ไขข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.period_sedirectorQueryAll();
          let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
          this.data_syslog.ApiKey = this.ApiKey;
          this.data_syslog.user_account = userSession.user_name;
          this.data_syslog.event_log = "update";
          this.data_syslog.page_log = "period_sedirector";
          this.data_syslog.table_log = "period_sedirector";
          this.data_syslog.date_times = this.date_today_log;
          await this.$http.post("data_syslog.insert.php", this.data_syslog);
        } else {
          Swal.fire({
            icon: "warning",
            title: "แก้ไขข้อมูลผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.editperiod_sedirectordialog = false;
      }
    },
    computed: {
      pages() {
        if (
          this.pagination.rowsPerPage == null ||
          this.pagination.totalItems == null
        )
          return 0;
  
        return Math.ceil(
          this.pagination.totalItems / this.pagination.rowsPerPage
        );
      },
      date_today_log() {
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, "0");
        let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = today.getFullYear() + 543;
        let time =
          today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        today = dd + "/" + mm + "/" + yyyy + "/" + time;
        return today;
      }
    }
  };
  </script>
  