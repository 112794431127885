<template>
    <div>
      <director_app_bar/>
      <v-container fluid>
        <base-material-card
          icon="mdi-clipboard-text"
          title="กำหนดการปฏิทินการสมัคร คัดเลือก ตำแหน่ง ผู้อำนวยการวิทยาลัย"
          class="px-5 py-3"
        >
          <v-card class="mb-4 pa-2">
            <v-row>
              <v-col cols="12" md="12">
                <v-timeline :dense="$vuetify.breakpoint.smAndDown">

                  <v-timeline-item color="grey lighten-1" fill-dot left small>
                    <v-card>
                      <v-card-title class="grey lighten-1 justify-end">
                        <h2 class="mr-4 white--text font-weight-light">
                          กำหนดการ <span class="red--text">ประกาศสมัคร ผู้อำนวยการวิทยาลัย</span> 
                        </h2>
                        <v-icon dark size="42">
                          mdi-calendar
                        </v-icon>
                      </v-card-title>
                      <v-container>
                        <v-row>
                          <v-col cols="12" md="12">
                              <PeriodDirectorRegis/>
                          </v-col>                       
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-timeline-item>
                  
                    
                  <v-timeline-item color="info lighten-1" fill-dot right>
                    <v-card>
                      <v-card-title class="info lighten-1">
                        <v-icon dark size="42" class="mr-4">
                          mdi-calendar
                        </v-icon>
                        <h2 class="white--text font-weight-light">
                         สอจ. (ตรวจสอบคุณสมบัติ) 
                        </h2>
                      </v-card-title>
                      <v-container>
                        <v-row>
                          <v-col cols="12" md="12">
                              <periodVecprovincesDirectorRegis/>
                          </v-col>                     
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-timeline-item>


                  <v-timeline-item color="grey lighten-1" fill-dot left small>
                    <v-card>
                      <v-card-title class="grey lighten-1 justify-end">
                        <h2 class="mr-4 white--text font-weight-light">
                          ประกาศรายชื่อ<span class="red--text">ผู้มีสิทธิ์เข้ารับการคัดเลือก</span> 
                        </h2>
                        <v-icon dark size="42">
                          mdi-calendar
                        </v-icon>
                      </v-card-title>
                      <v-container>
                        <v-row>
                          <v-col cols="12" md="12">
                              <PeriodDirectorNotice/>
                          </v-col>                       
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-timeline-item>
                  


                  <v-timeline-item color="info lighten-1" fill-dot right>
                    <v-card>
                      <v-card-title class="info lighten-1">
                        <v-icon dark size="42" class="mr-4">
                          mdi-calendar
                        </v-icon>
                        <h2 class="white--text font-weight-light">
                         สอจ./สถาบัน/ศูนย์ส่งเสริม (ประเมิน ภาค ข ส่วนที่ 1) 
                        </h2>
                      </v-card-title>
                      <v-container>
                        <v-row>
                          <v-col cols="12" md="12">
                              <PeriodVecprovincesDirectorAssess/>
                          </v-col>                     
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-timeline-item>
  
                             
               


  
  




                  <v-timeline-item color="grey lighten-1" fill-dot left small>
                    <v-card>
                      <v-card-title class="grey lighten-1 justify-end">
                        <h2 class="mr-4 white--text font-weight-light">
                          ประกาศ<span class="red--text">ผลการคัดเลือก</span> 
                        </h2>
                        <v-icon dark size="42">
                          mdi-calendar
                        </v-icon>
                      </v-card-title>
                      <v-container>
                        <v-row>
                          <v-col cols="12" md="12">
                              <PeriodDirectorNoticeS/>
                          </v-col>                       
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-timeline-item>

  

                  <v-timeline-item color="success lighten-1" fill-dot right>
                    <v-card>
                      <v-card-title class="success lighten-1">
                        <v-icon dark size="42" class="mr-4">
                          mdi-calendar
                        </v-icon>
                        <h2 class="white--text font-weight-light">
                         รายงานตัวเลือกสถานศึกษา
                        </h2>
                      </v-card-title>
                      <v-container>
                        <v-row>
                          <v-col cols="12" md="12">
                            <perioddirectorChooseCollege/>

                          </v-col>                     
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-timeline-item>





  
                  <v-timeline-item color="purple lighten-2" fill-dot left>
                    <v-card>
                      <v-card-title class="purple lighten-2">
                        <v-icon dark size="42" class="mr-4">
                          mdi-calendar
                        </v-icon>
                        <h2 class="white--text font-weight-light">
                         วิทยาลัยปลายทาง (รับรายงานตัว) 
                        </h2>
                      </v-card-title>
                      <v-container>
                        <v-row>
                          <v-col cols="12" md="12">
                              <periodCollegeDirectorReport/>
                          </v-col>                     
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-timeline-item>
  
  
              
                </v-timeline>
              </v-col>
            </v-row>
          </v-card>
        </base-material-card>
      </v-container>
    </div>
  </template>
  
  <script>
import PeriodDirectorNotice from '../../../components/admin/director/perioddirectorNotice.vue';
import PeriodDirectorNoticeA from '../../../components/admin/director/perioddirectorNoticeA.vue';
import PeriodDirectorNoticeC from '../../../components/admin/director/perioddirectorNoticeC.vue';
import PeriodDirectorNoticeS from '../../../components/admin/director/perioddirectorNoticeS.vue';
import perioddirectorChooseCollege from '../../../components/admin/director/perioddirectorChooseCollege.vue';
import periodCollegeDirectorReport from '../../../components/admin/director/periodCollegeDirectorReport.vue';


import PeriodDirectorRegis from '../../../components/admin/director/perioddirectorRegis.vue';
import PeriodVecprovincesDirectorAssess from '../../../components/admin/director/periodVecprovincesdirectorAssess.vue';
import PeriodVecprovincesDirectorAssessB from '../../../components/admin/director/periodVecprovincesdirectorAssessB.vue';

import periodVecprovincesDirectorRegis from '../../../components/admin/director/periodVecprovincesdirectorRegis.vue';
import director_app_bar from '../../../components/admin/director/director_app_bar.vue';




  
  
  export default {
      name: "HRvecTransferTeachers",
      data() {
          return {
              loading: true,
              ApiKey: "HRvec2021",
          };
      },
      mounted() { },
      methods: {},
      components: { PeriodDirectorRegis, periodVecprovincesDirectorRegis, PeriodVecprovincesDirectorAssess, PeriodDirectorNotice, PeriodDirectorNoticeA, PeriodVecprovincesDirectorAssessB, PeriodDirectorNoticeC, PeriodDirectorNoticeS, perioddirectorChooseCollege, periodCollegeDirectorReport, director_app_bar }
    }
  </script>
  
  <style lang="scss" scoped></style>
  