<template>
  <div>
    <v-bottom-navigation
      color="info"
      horizontal
      :background-color="colorfrist"
      dark
      height="40px"
    >
      <v-btn to="/admin/timelines/examination_Director" class="mr-5">
        <span>กำหนดการ ผอ.</span>
        <v-icon>mdi-numeric-1-box</v-icon>
      </v-btn>

      <v-btn to="/admin/director_app" class="mr-5">
        <span>ข้อมูลผู้สมัคร</span>
        <v-icon>mdi-numeric-2-box</v-icon>
      </v-btn>

      <v-btn to="/admin/director_assessment/director_app_a" class="mr-5">
        <span>ภาค ก.</span>
        <v-icon>mdi-numeric-3-box</v-icon>
      </v-btn>

      <v-btn to="/admin/director_assessment/director_app_b" class="mr-5">
        <span>ภาค ข.</span>
        <v-icon>mdi-numeric-4-box</v-icon>
      </v-btn>

      <v-btn to="/admin/director_assessment/director_app_c" class="mr-5">
        <span>ภาค ค.</span>
        <v-icon>mdi-numeric-5-box</v-icon>
      </v-btn>
    </v-bottom-navigation>

     <v-bottom-navigation
      color="info"
      horizontal
      :background-color="colorsecond"
      dark
      height="40px"
    >
    <v-btn to="/admin/director_assessment/director_app_notice" class="mr-5">
        <span>ประกาศผล</span>
        <v-icon>mdi-numeric-6-box</v-icon>
      </v-btn>

      <v-btn to="/admin/director_assessment/director_college_manpower" class="mr-5">
        <span>ตำแหน่งว่างสถานศึกษา</span>
        <v-icon>mdi-numeric-7-box</v-icon>
      </v-btn>

      
      <v-btn to="/admin/director_assessment/director_app_choose" class="mr-5">
        <span>การรายงานตัวเลือกสถานศึกษา</span>
        <v-icon>mdi-numeric-8-box</v-icon>
      </v-btn>

      <v-btn to="/admin/director_assessment/director_app_report_college" class="mr-5">
        <span>การรายงาน ณ สถานศึกษา</span>
        <v-icon>mdi-numeric-9-box</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
export default {
  name: "HRvecPersonnelmenu",

  data() {
    return {
      personnels: false
    };
  },

  mounted() {},

  methods: {
    async personnelselect() {
      this.personnels = true;
    }
  },
  computed: {
    colorfrist() {
      return "#414141";
    },
    colorsecond() {
      return "#548858";
    }
  }
};
</script>

<style lang="scss" scoped></style>
